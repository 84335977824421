<template v-if="categories">
  <template v-for="(categ, index) in categories" :key="index" >
    <div :id="'categ' + index"  :class="'categ-' + categ.id + ' categ'">
      <div class="scrollfakeid" :id="'categ-mobile' + index"></div>

      <div class="row">
        <h1 class="col-12 video-categ-title">{{ categ.title }}</h1>
        <big-video-card v-if="isMobile"  :video="categ.videos[0]" :categ-id="categ.id"/>

        <thumb-video-list :videos="categ.videos" :categ-id="categ.id"/>
      </div>
      <div class="col-xs-12 horizontal-bottom-line"></div>
    </div>
  </template>
</template>

<script>

import BigVideoCard from "@/components/video/BigVideoCard.vue";
import ThumbVideoList from "@/components/video/ThumbVideoList.vue";
// import SkeletonBox from '@/components/blocks/SkeletonBox.vue';

export default {
  name: "CategList",
  props: ['loading'],
  components: {
    "big-video-card": BigVideoCard,
    "thumb-video-list": ThumbVideoList,
    // SkeletonBox,
  },
  computed: {
    categories() {
      return this.$store.getters['vlog/categories'];
    },

    isMobile() {
        return this.$mq !== 'lg' ? false : true;
    }

  }

};
</script>

<style scoped>
  .scrollfakeid {
    position: absolute;
    left: 0;
    padding-top: 60px;
    width: 100%;
    top: -60px;
  }

  .categ {
    position: relative;
  }

</style>