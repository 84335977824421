<template>
  <div class="col-md-8 big-video main-video-videocategid">
    <div class="row">
      <div class="col-12 big-video-wrp" :data-id="categId" :data-url="video.video_url">
        <video-player :video="video" :categ-id="categId" />
        <!-- <iframe width="560" height="315" :src="'//www.youtube.com/embed/' + video_spec.id" frameborder="0" allowfullscreen></iframe> -->
      </div>

      <input type="hidden" name="video_id" class="video_id" value="img_id" />
      <div class="big-item-inner col-12">
        <h6 itemprop="headline">{{ video.title }}</h6>
        <div itemprop="articleBody" class="big-video-content-wrp">
          <p v-html="video.description.substring(0, 300) + '...'"></p>
        </div>
        <div>
          <router-link
            :to="{ name: 'videoSingle', params: { slug: video.slug } }"
            class="video_link"
            data-id=""
            title=""
            href="#"
            >&gt; mai multe detalii</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoPlayer from "@/components/video/VideoPlayer.vue";

export default {
  props: ["categId"],
  components: {
    VideoPlayer,
  },
  computed: {
    video() {
      const currentVideos = this.$store.getters["vlog/currentVideos"];
      const finalVideo = currentVideos.filter((video) => video.categ === this.categId);
      return finalVideo[0].video;
    },
  },

  mounted() {
    this.video;
  },
};
</script>

<style scoped>
.big-video-content-wrp >>> span {
  font-family: "Raleway" !important;
  font-size: 14px !important;
  line-height: 1.7;
}
</style>
