<template>
  <div class="section" id="filter-mobile">
    <div class="filters filter-content filter-header">
      <a class="close" @click="closeFilter"></a>
      <div class="filter-items filter-default" id="filter">
        <div class="d-flex item">
          <div class="filter-name">
            <span class="filter-label">Filtreaza:</span>
          </div>

          <div class="button-group js-radio-button-group" data-filter-group="categ">
            <div v-for="(categ, index) in categories" :key="index" class="filter-row">
              <button
                class="button"
                :data-filter="categ.id"
                @click="goto('categ-mobile' + index)"
              >
                <a :class="'categ' + index">{{ categ.title }} </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GeneralFilter",
  props: ["categories"],
  emits: ["closeMobileFilter"],

  methods: {
    goto(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      this.$emit("closeMobileFilter");
    },

    closeFilter() {
      this.$emit("closeMobileFilter");
    },

    handleScroll() {
      const sections = document.querySelectorAll(".categ");
      sections.forEach((section, index) => {
        // let height = section.offsetHeight / 2;
        if (
          window.scrollY >= section.offsetTop - 200 &&
          window.scrollY <= section.offsetTop + section.offsetHeight - 150
        ) {
          document.querySelector("a.categ" + index).classList.add("active");
        } else {
          document.querySelector("a.categ" + index).classList.remove("active");
        }
      });
    },
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
#filter .item a:hover,
#filter a.mPS2id-highlight,
#filter a.active {
  background: #97040c;
  border-radius: 28px;
}

.item {
  flex-wrap: wrap;
}

.filter-header .close {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 32px;
  height: 32px;
  opacity: 0.5;
  z-index: 1;
}

.filter-header .close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 33px;
  width: 3px;
  background-color: #fff;
}
.filter-header .close:before {
  transform: rotate(45deg);
}
.filter-header .close:after {
  transform: rotate(-45deg);
}

.filter-items.filter-default .item {
  padding: 15px;
}

.filter-items.filter-default .button-group {
  padding: 0;
}

.sticky#filter-mobile {
  position: fixed;
  right: 0px;
  z-index: 99;
  margin: 0;
  top: 100px;
  width: 100%;
}

@media (max-width: 576px) {
  .sticky#filter-mobile {
    top: 60px;
  }
}
</style>
