<template>
    <div class="col-xl-3 col-lg-4 col-md-6 mb-4">
        <div class="item">
            <img :src="'http://img.youtube.com/vi/' + getId(video.video_url) + '/0.jpg'" :alt="video.title">

            <div class="block-item-inner">
                <h6 itemprop="headline">{{ video.title }}</h6>
                <div itemprop="articleBody" class="block-content-wrp">
                    <p v-html="video.description"></p>   
                </div>
                <div class="see-video">
                    <router-link 
                        :to="{ name: 'videoSingle', params: { slug: video.slug } }" 
                        class="video_link" 
                        title="" 
                    >&gt; mai multe detalii</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    props: ['video'], 

    methods: {

    getId(url) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        return (match && match[2].length === 11)
            ? match[2]
            : null;
        },
    },
    
}
</script>

<style scoped lang='scss'>


.item {
  img {
    width: 100%;
  }

  .block-item-inner {
    border: 0px;
    height: 300px;
    position: relative;
    background-color: #fff;

      h6 {
        min-height: 0;
        margin-bottom: 8px;
        color: #1F2126;
        margin-top: 0;
        padding: 30px 20px 10px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 105px;
        display: -webkit-box;
        font-family: Montserrat SemiBold;
        font-size: 18px !important;
        line-height: 25px;
        vertical-align: middle;
      }

      .block-content-wrp {
        font-family: Montserrat Light;
        color: #060606;
        font-size: 14px;
        padding: 0 20px;
        line-height: 1.7;
        overflow: hidden;
        max-height: 90px;
      }

      .see-video a {
        font-size: 12px;
        text-transform: uppercase;
        position: absolute;
        bottom: 20px;
        left: 20px;
        text-align: left;
        text-overflow: ellipsis;
        text-decoration: underline;
        height: 30px;
        color: #97040c;
        font-family: 'Montserrat Bold';
      }
  }
}

</style>