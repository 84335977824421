<template>
  <div class="col-12 col-md-4">
    <div class="thumbnail-video-wrp">
      <thumb-video-card v-for="(video, index) in videos" :key="index" :video="video" :categ-id="categId" :class="mobileCateg(index)"/>
    </div>
  </div>
</template>

<script>
import ThumbVideoCard from "@/components/video/ThumbVideoCard.vue";

export default {
  name: "ThumbVideoList",
  props: ['videos','categId'],
  components: {
    "thumb-video-card": ThumbVideoCard,
  },
  methods: {
    mobileCateg(index) {
      var viewportWidth = window.innerWidth || document.documentElement.clientWidth;
      if (viewportWidth < 768 && index === 0)
        return 'active first';
    }
  }
};
</script>