<template>
  <div class="video-single">
    <page-header />
    <div class="container main-area">
      <div v-if="video.video_url" class="first-section video">
        <main-video-card :video="video" :video-id="getId(video.video_url)" />

        <video-cards-list :videos="videos" />
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/page_header/PageHeader.vue";
import MainVideoCard from "@/components/video/MainVideoCard.vue";
import VideoCardsList from "@/components/video/VideoCardsList.vue";

import fitvids from "fitvids";

export default {
  name: "VideoSingle",
  data() {
    return {};
  },
  components: {
    PageHeader,
    MainVideoCard,
    VideoCardsList,
  },
  computed: {
    videos() {
      return this.$store.getters["vlog/categVideos"].slice(0, 8);
    },
    video() {
      return this.$store.getters["vlog/video"];
    },
  },

  methods: {
    getId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[2].length === 11 ? match[2] : null;
    },
    getVideo(slug) {
      this.$store.dispatch("vlog/getVideoOnAPI", slug);

      fitvids();
    },
  },

  mounted() {
    // const categories = this.$store.getters['vlog/categories'];
    // if (categories.length === 0) {
    //   this.$store.dispatch('vlog/getVideoOnAPI', this.$route.params.slug);
    // } else {
    // }
    this.getVideo(this.$route.params.slug);
  },

  updated() {
    fitvids();
  },

  beforeRouteUpdate(to, from, next) {
    if (to.params.slug !== from.params.slug) {
      this.getVideo(to.params.slug);
    }

    next();
  },
};
</script>

<style scoped lang="scss">
.video-single {
  background-color: #f5f4f2;
}
</style>
