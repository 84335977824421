<template>
    <div class="row">

        <div class="col-12">
            <h3>Alte videouri:</h3>
        </div>

        <!-- list related videos -->
        <div class="col-12">
            <div class='row'>
                <video-card v-for="video in videos" :key="video.id" :video="video"/>
            </div>
        </div>

    </div>
</template>

<script>
import VideoCard from "@/components/video/VideoCard.vue";

export default {

    components: {
      VideoCard,
    }, 

    props: ['videos'],
    
}
</script>

<style scoped lang='scss'>

h3 {
    font-size: 24px;
    color: #2C2626;
    font-family: 'Raleway';
    margin-bottom: 30px;
    margin-top: 35px;
    font-weight: 300;
}

</style>