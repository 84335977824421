<template>
  <div>
    <h1>PE FOC a pregatit pentru tine cele mai bune explicatii VIDEO</h1>
    <div>
      <div class="video-page-description">
        Convinge-te de calitatea inalta a produselor noastre urmarind modul de
        fabricare, demonstratii si prezentarea produselor in detaliu. Poti gasi
        idei de amplasare a biosemineelor sau de constructie a semineelor.
        Suntem increzatori in calitatea si buna functionare a produselor
        comercializate de noi, si asteptam sa te convingi si tu!
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoHeader",
};
</script>

<style scoped>

.video-page-description {
  font-size: 14px;
}

</style>