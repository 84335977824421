<template>
  <div @click="onClick">
    <video
      :id="'video_' + categId"
      ref="videoPlayer"
      class="video-js vjs-default-skin vjs-fluid"
      controls
      preload="auto"
      :poster="video.preview_image"
      @ready="onPlayerReady($event)"
      @play="onPlayerPlay($event)"
    ></video>
  </div>
</template>

<script>
import videojs from "video.js";

export default {
  name: "VideoPlayer",
  props: ["categId"],
  data() {
    return {
      video_id: "",
      player: null,
    };
  },
  computed: {
    video() {
      const currentVideos = this.$store.getters["vlog/currentVideos"];
      const finalVideo = currentVideos.filter((video) => video.categ === this.categId);
      return finalVideo[0].video;
    },
    videoImage() {
      return (
        "https://i.ytimg.com/vi/" + this.getId(this.video.video_url) + "/hqdefault.jpg"
      );
    },
  },

  methods: {
    getId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[2].length === 11 ? match[2] : null;
    },
    async onClick() {
      const video = {
        type: "video/youtube",
        src: this.video.video_url,
      };
      const allPlayers = videojs.getPlayers();
      Object.entries(allPlayers).forEach((player) => {
        if (!player[1].paused()) {
          player[1].pause();
        }
      });
      //   console.log(this.player.src());
      this.player.src(video);
      //   console.log(this.player.src());
      const self = this;
      setTimeout(() => {
        // console.log(self);
        // console.log(self.player.src());
        self.player.play();
      }, 2000);
    },

    onPlayerReady(player) {
      console.log("player ready!", player);
    },
    onPlayerPlay(player) {
      console.log("player play", player);
    },
  },

  mounted() {
    this.video_id = this.getId(this.video.video_url);
    this.player = videojs(this.$refs.videoPlayer);
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
