<template>
    <div>
        <div class="row">
            <h1 class="col-12">
            <skeleton-box :min-width="20" :max-width="50"/>
            </h1>
            <div class="col-md-8">
            <div class="row">
                <div class="col-12">
                <skeleton-box height="410px" width="100%"/>
                </div>
                <div class="big-item-inner col-12">
                <h6>
                    <skeleton-box :min-width="20" :max-width="90"/>
                </h6>
                <div>
                    <skeleton-box/>
                    <skeleton-box/>
                    <skeleton-box/>
                    <skeleton-box/>
                </div>
                </div>
            </div>
            </div>

            <div class="col-12 col-md-4">
            <div>
                    <div v-for="(n, index) in 6" :key="index"
                    class="row thumb-video"                          
                    >
                    <div class="col-12 col-sm-7 col-md-11 col-lg-11 col-xl-6">
                        <div>
                        <span>
                            <skeleton-box height="95px" width="100%"/>
                            <!-- <span class="vertical-align"></span> -->
                        </span>
                        <div></div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-5 col-md-11 col-lg-11 col-xl-6">
                        <div>
                        <skeleton-box/>
                        <skeleton-box/>
                        <skeleton-box/>
                        </div>
                    </div>
                    </div>                    
            </div>
            </div>
        </div>
        <div class="col-xs-12 horizontal-bottom-line"></div>
    </div>
</template>

<script>
import SkeletonBox from '@/components/blocks/SkeletonBox.vue';

export default {
   components: {
    SkeletonBox
  },   
}
</script>