<template>
    <div 
      class="row thumb-video"
      :id="categId + '-thumb-video-' + video.id"
      :data-url="video.video_url"
      :data-poster="video_id"
      :alt="video.title"
      :data-id="categId"
      :data-title="video.title"
      :data-href="'/video/' + video.slug"
      :data-description="video.description"
      :data-video-id="video.id"
      
    >
      <div class="col-12 col-sm-7 col-md-11 col-lg-11 col-xl-6 mobile-video">
        <div class="thumb-img-wrp clip-link" >
          <span class="clip">
            <img
              itemprop="image"
              :src="video.preview_image"
              :srcset="
                video.preview_image + ' 320w,' + 
                video.preview_image + ' 120w'"
              sizes="(min-width: 1200px) 120px, (min-width: 768px) 320px, 100vw"
              alt=""
            />
            <span class="vertical-align"></span>
          </span>
          <span class="overlay" @click="loadVideo()"></span>
          <div class="mobile-wrapper"></div>
        </div>
      </div>
      <div class="col-12 col-sm-5 col-md-11 col-lg-11 col-xl-6 text-column mobile-text">
        <div class="thumb-item-inner video mobile-title">
          <span class="video-title">{{ video.title }}</span>
        </div>
        <div class="mobile-description" style="display: none">
          <p v-html="video.description"></p>
        </div>
        <div class="mobile-link" style="display: none">
          <a class="video_link" data-id="" title="" href=""
            >&gt; mai multe detalii</a
          >
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: ["video","categId"],

  data() {
    return {
      video_id: '',
    };
  },

  computed: {
    videoImage() {
      return 'https://img.youtube.com/vi/' + this.video_id + '/0.jpg';
    }
  },

  methods: {
   getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
    }, 
    loadVideo() {
      const payload = {
        categ: this.categId,
        video: this.video,
        video_id: this.video_id,
      }
      this.$store.dispatch('vlog/loadThumbVideo', payload);
    }
  },

  mounted() {
    // console.log(this.video);
    this.video_id = this.getId(this.video.video_url);
  },
};
</script>

<style>

@media (max-width: 768px) {

  .active .mobile-video {
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
  }

  .active .mobile-video .thumb-img-wrp.clip-link {
  }

  .active .mobile-video img {
      height: 320px;
  }

  .active .mobile-text span.video-title {
      font-family: Raleway;
      font-size: 20px;
      font-weight: 600;
      color: #33343d;
      line-height: normal;
      margin: 30px 0 15px;
      /* width: 100%; */
      margin: 30px 0 15px;
  }

  .active .mobile-text .mobile-title {
    margin: 30px 0 15px;
  }

  .active .mobile-description {
    display: block !important;
  }

  .active .mobile-link {
    display: block !important;
  }

  .active .mobile-text {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    padding-left: 15px;
  }

  .active a.video_link {
    margin-top: 20px;
  }

  .first.active .mobile-video > div > span {
    display: block;
  }

  .active .mobile-video > div > span {
    display: none;
  }

  .active .mobile-video > div  .video-js {
    display: block !important;
  }
}

</style>