<template>
  <div id="mm-0" class="mm-page mm-slideout">
    <page-header :item="header_image" />
    <transition name="fade">
      <navbar-filter
        v-if="windowWidth > 768"
        :categories="categories"
        :class="{ sticky: scrollNavbar }"
      />
    </transition>
    <navbar-filter-mobile
      v-show="windowWidth < 768 && navbarFilterMobile"
      :categories="categories"
      :class="{ sticky: scrollNavbar }"
      @closeMobileFilter="closeFilter"
    />
    <mobile-filter @click="showMobileFilter" />
    <div class="container main-area">
      <div class="first-section videos">
        <div id="videos">
          <div id="video-list">
            <video-header />

            <skeleton-video-categ-list v-if="isLoading" />

            <categ-list v-else />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "video.js/dist/video-js.css";
require("videojs-youtube");

import VideoHeader from "@/components/video/VideoHeader.vue";
import CategList from "@/components/video/CategList.vue";
import NavbarFilter from "@/components/filter/NavbarFilter.vue";
import NavbarFilterMobile from "@/components/filter/NavbarFilterMobile.vue";
import PageHeader from "@/components/page_header/PageHeader.vue";
import MobileFilter from "@/components/filter/MobileFilter.vue";
import SkeletonVideoCategList from "@/components/blocks/skeleton/SkeletonVideoCategList.vue";

export default {
  name: "Video",
  data() {
    return {
      windowWidth: window.innerWidth,
      scrollNavbar: false,
      navbarFilterMobile: false,
      isLoading: true,
    };
  },
  components: {
    "video-header": VideoHeader,
    "categ-list": CategList,
    "navbar-filter": NavbarFilter,
    "page-header": PageHeader,
    "mobile-filter": MobileFilter,
    NavbarFilterMobile,
    SkeletonVideoCategList,
  },
  computed: {
    categories() {
      return this.$store.getters["vlog/categories"];
    },
    header_image() {
      return {
        path: this.$store.getters["seo/seo_image"],
      };
    },
  },

  methods: {
    handleScroll() {
      // console.log(window.scrollY);
      if (window.scrollY > 60) {
        this.scrollNavbar = true;
      } else {
        this.scrollNavbar = false;
      }
    },
    showMobileFilter() {
      this.navbarFilterMobile = true;
    },
    closeFilter() {
      this.navbarFilterMobile = false;
      this.$store.dispatch("filter/actionImgFilter", true);
    },
  },

  mounted() {
    this.$store.dispatch("vlog/getCategories").then(() => {
      this.isLoading = false;
    });
    window.addEventListener("scroll", this.handleScroll);
    this.$store.commit("seo/setParams", {
      seo_title: "Video seminee, focare, bioseminee - Noutati si informatii",
      seo_description:
        "Video despre seminee si sobe pentru a-ti oferi cele mai clare informatii. Vizioneaza in detaliu focarele si biosemineele noastre si alege in cunostinta de cauza.",
      seo_keywords: "video semineu, video focare, bioseminee video",
      seo_image: require("@/assets/images/video-bg.webp"),
    });
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
@import "../../assets/css/video.css";
</style>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
