<template>

    <div v-if="video.title" class="row">

        <div class="col-12">
            <h3>{{ video.title }}</h3>
        </div>

        <div class="col-12">
            <iframe :src="'https://www.youtube.com/embed/' + videoId" frameborder="0" allowfullscreen ></iframe>
        </div>

        <!-- video description -->
        <div class="col-12">
            <p v-html="video.description" class='descr'></p>
        </div>
        
    </div>

</template>

<script>
export default {

    props: ['video','videoId'],

    unmounted() {
        this.$store.dispatch('vlog/resetVideo');
    }
    
}
</script>

<style scoped lang='scss'>

h3 {
    font-size: 24px;
    color: #2C2626;
    font-family: 'Raleway';
    margin-bottom: 30px;
    margin-top: 35px;
    font-weight: 300;
}

.descr {
    color: #050505;
    font-size: 14px;
    font-family: 'Montserrat Regular';
    font-weight: 300;
    display: inline-block;
    width: 100%;
    margin-top: 30px;
}

</style>